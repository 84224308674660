<template>
  <div>

    <b-row>
      <b-col md="12">
        <b-card>
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              {{ $t('Faaliyet türüne ait bilgiyi değiştirdikten sonra sağ tarafındaki kaydet butonuna tıklamanız gerekmektedir.') }}
            </div>
          </b-alert>
          <div class="d-flex align-items-center justify-content-end">

            <b-button
              size="sm"
              variant="primary"
              @click="addNew()"
            >
              {{ $t('Faaliyet Tipi Ekle') }}
            </b-button>
          </div>
          <hr>

          <b-list-group-item
            v-for="(item,index) in activityTypes"
            :key="index"
            tag="li"
            :variant="item.name==''?'danger':''"
            class="d-flex justify-content-between align-items-center"
          >

            <b-input-group>
              <b-input-group-prepend>
                <v-select
                  v-model="item.color"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="colorOptions"
                  label="label"
                  :reduce="val => val.value"
                  input-id="color"
                  :clearable="false"
                >

                  <template #option="{ value, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${value}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>

                  <template #selected-option="{ value, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${value}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>
                </v-select>

              </b-input-group-prepend>
              <b-form-input

                v-model="item.name_edit"
                :placeholder="$t('Faaliyet Türü Adı')"
              />
            </b-input-group>

            <b-button-toolbar>
              <b-button-group>
                <b-button
                  v-if="item.name_edit!=''"
                  variant="outline-primary"
                  @click="saveData(item)"
                >
                  <feather-icon icon="SaveIcon" />
                </b-button>

                <b-button

                  variant="outline-primary"
                  @click="item.name_edit=item.name"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-danger"
                  @click="deleteData(index,item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-list-group-item>

        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BButtonToolbar, BButtonGroup, VBModal, BAlert, BListGroupItem, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import calendarStoreModule from '../calendarStoreModule'

export default {
  components: {
    BRow, BCol, BButton, BFormInput, BButtonToolbar, BButtonGroup, BAlert, BListGroupItem, BInputGroup, BInputGroupPrepend, vSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      selectedactivity_type: null,

    }
  },
  methods: {
    addNew() {
      const activityTypeData = JSON.parse(JSON.stringify(this.blankActivityTypeData))

      store.dispatch('appactivity/addActivityType', activityTypeData)
        .then(response => {
          const { data } = response.data
          data.options = []
          data.name_edit = data.name
          this.activityTypes.push(data)
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    // eslint-disable-next-line no-unused-vars
    saveData(item) {
      const formData = JSON.parse(JSON.stringify(item))
      formData.name = formData.name_edit
      store.dispatch('appactivity/updateActivityType', formData)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          item.name = item.name_edit

          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    // eslint-disable-next-line no-unused-vars
    deleteData(index, id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Faaliyet Türünü Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('appactivity/deleteActivityType', { id })
            .then(() => {
              this.activityTypes.splice(index, 1)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'appactivity'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })
    const blankActivityTypeData = {
      id: 0,
      name: '',
      color: '',

    }
    const colorOptions = [
      { label: 'primary', value: 'primary' },
      { label: 'secondary', value: 'secondary' },
      { label: 'success', value: 'success' },
      { label: 'warning', value: 'warning' },
      { label: 'danger', value: 'danger' },
      { label: 'info', value: 'info' },
    ]
    const activityTypes = ref([])
    store.dispatch('app/fetchActivityTypes').then(response => {
      const { data } = response.data
      data.forEach(val => {
        // eslint-disable-next-line no-param-reassign
        val.name_edit = val.name
        // eslint-disable-next-line no-param-reassign
        val.options = []
      })
      activityTypes.value = data
    })

    const onSubmit = () => {

    }

    // ? dataField is field name of `userDataSocial` object

    return {
      activityTypes,
      onSubmit,
      blankActivityTypeData,
      colorOptions,
    }
  },
}
</script>

<style>

</style>
